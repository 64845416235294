import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import Container from "layouts/Container";
import Hero from "components/Hero";

import "./applicant-and-candidate-privacy-notice.scss";

const ApplicantAndCandidatePrivacyNotice = ({ data: { page } }) => {

  const { t } = useTranslation();
  const { updatedAt, seo, title} = page.frontmatter

  return (
    <Layout>
      <SEO title={(seo && seo.title) || title} description={seo && seo.description} image={seo && seo.image} />
      <Container>
        <div className="applicant-and-candidate-privacy-notice">
          <time className="applicant-and-candidate-privacy-notice__date t-light-gray">
            {t('Updated at')}: {updatedAt}
          </time>
          <Hero
            title={title}
          />
          <hr className="applicant-and-candidate-privacy-notice__divider" />
          <div
            className="applicant-and-candidate-privacy-notice__body t-text"
            dangerouslySetInnerHTML={{ __html: page.html }}></div>
        </div>
      </Container>
    </Layout>
  );
};

export default ApplicantAndCandidatePrivacyNotice;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(
      fields: { langKey: { eq: $language } }
      fileAbsolutePath: { regex: "//pages/applicant-and-candidate-privacy-notice.md/" }
    ) {
      html
      frontmatter {
        title
        updatedAt: updated_at(formatString: "Y-MM-DD")
        seo {
          ...Seo
        }
      }
    }
  }
`;
